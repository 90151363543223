<template>
  <v-responsive>
    <SubMenuStoreMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuStoreMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>
          
          <SubMenuStore></SubMenuStore>

          
              <v-row class="my-0 mx-0" style="background: #fff">
                <v-col>
                     <GlobalSideBannerBottom></GlobalSideBannerBottom>
                </v-col>
              </v-row>


        </v-col>

        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>


          <v-row class="my-3 mx-0" no-gutters style="background: #fff">
            <v-col>
                  <GlobalSubBannerTop></GlobalSubBannerTop>
            </v-col>
          </v-row>




          <v-responsive class="mt-10">
              <h3>로또 6/45의 히스토리를 안내해 드립니다.</h3>
              <p>제 1회부터 제 986회까지 누적 히스토리 입니다.</p>
              <v-sheet outlined class=" mt-1 ">
                  <v-simple-table >
                    <template >
                      <!--
                      <thead>
                        <tr>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            구분
                          </th>
                          <th class="text-center datatable_header" style="font-size:13px;">
                            누적 총계
                          </th>
                        </tr>
                      </thead>-->
                      <tbody>
                        
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            현재까지 총 판매금액
                          </th>
                          <td class="text-center">
                            61,143,341,035,000 원
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            현재까지의 총 당첨금액
                          </th>
                          <td class="text-center">
                            30,571,670,517,500 원
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            현재까지 누적 1등 당첨자수
                          </th>
                          <td class="text-center">
                            7,112 명
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            현재까지 누적 2등 당첨자수
                          </th>
                          <td class="text-center">
                            42,375 명
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            현재까지 누적 3등 당첨자수
                          </th>
                          <td class="text-center">
                            	1,617,564 명
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            현재까지 누적 1등 당첨지급금액
                          </th>
                          <td class="text-center">
                            14,527,588,113,339 원
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            현재까지 누적 2등 당첨지급금액
                          </th>
                          <td class="text-center">
                            2,420,402,282,155 원
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            현재까지 누적 3등 당첨지급금액
                          </th>
                          <td class="text-center">
                           2,420,816,495,743 원
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            평균 1등 당첨금액
                          </th>
                          <td class="text-center">
                            2,042,686,743 원
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            최고 1등 당첨금액
                          </th>
                          <td class="text-center">
                           40,722,959,400 원
                          </td>
                        </tr>
                        <tr>
                          <th class="text-center" style="background:#f1f1f1;font-size:14px;">
                            최저 1등 당첨금액
                          </th>
                          <td class="text-center">
                            405,939,950 원
                          </td>
                        </tr>

                      </tbody>
                    </template>
                  </v-simple-table>
              </v-sheet>
          </v-responsive>
	
	

	
	

              <v-row class="my-3 mx-0" no-gutters style="background: #fff">
                <v-col>
                     <GlobalSubBannerBottom></GlobalSubBannerBottom>
                </v-col>
              </v-row>
	
	
	




        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Store",
  components: {
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "판매점 분석" },
      { text: "로또 역사" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
  }),
};
</script>
<style></style>
